<!--
 * @Author: your name
 * @Date: 2022-03-08 14:00:48
 * @LastEditTime: 2022-03-15 18:19:28
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ciic-web-h5\src\views\Model\M2.vue
-->
<template>
  <div class="M2">
    <div class="M2_top" @click="topClick">
      <van-image
        fit="cover"
        :src="titleImg.advertImage"
        v-if="titleImg.advertImage"
      />
    </div>
    <div class="M2_main">
      <div
        v-for="item in iconList"
        :key="item.id"
        :class="item.locationUrl ? 'icon' : 'icon no_icon'"
        @click="IconClick(item)"
      >
        <van-image fit="cover" :src="item.itemValue" />
      </div>
    </div>
    <div class="M2_bottom" @click="bottomClick">
      <van-image
        width="100%"
        fit="cover"
        :src="bottomImg.advertImage"
        v-if="bottomImg.advertImage"
      />
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage } from "vant";
import { getTSpecificById } from "@/api/model";
import { findAdById } from "@/api/model";
import getJSSDK from "@/utils/wxShare";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    VanImage,
    copyrightIp,
  },
  data() {
    return {
      titleImg: {
        advertImage: "",
        advertUrl: "",
      },
      iconList: [],
      bottomImg: {
        advertImage: "",
        advertUrl: "",
      },
    };
  },
  mounted() {
    this.getTSpecific();
  },
  methods: {
    topClick() {
      window.location.href = this.titleImg.advertUrl;
    },
    bottomClick() {
      window.location.href = this.bottomImg.advertUrl;
    },
    IconClick(data) {
      if (data.locationUrl) {
        window.location.href = data.locationUrl;
      }
    },
    getTSpecific() {
      let tspecificId = window.location.hash.split("?")[1].split("=")[1];
      getTSpecificById({ tspecificId }).then((res) => {
        let data = res.data.data.tspecificTempItems;
        getJSSDK({
          title: res.data.data.tspecific.specificName || "中智薪税",
          link: this.$route.fullPath,
        });

        if (
          data[0].itemValue.indexOf("http") == -1 ||
          data[0].itemValue.indexOf("weixin") == -1
        ) {
          const id = data[0].itemValue;
          findAdById({ id }).then((res) => {
            this.titleImg.advertImage = res.data.data.advertImage;
            this.titleImg.advertUrl = res.data.data.advertUrl;
          });
          this.iconList = data.slice(1, 10);
          if (data.length == 11) {
            const id = data[data.length - 1].itemValue;
            findAdById({ id }).then((res) => {
              this.bottomImg.advertImage = res.data.data.advertImage;
              this.bottomImg.advertUrl = res.data.data.advertUrl;
            });
          }
        } else {
          this.iconList = data.slice(0, 9);
          if (data.length == 10) {
            const id = data[data.length - 1].itemValue;
            findAdById({ id }).then((res) => {
              this.bottomImg.advertImage = res.data.data.advertImage;
              this.bottomImg.advertUrl = res.data.data.advertUrl;
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.M2_top {
  width: 100%;
  background: #fff;
  .van-image {
    width: 100%;
    height: 200px;
    border-radius: 100% 100% 100% 100% / 0% 0% 25% 25%;
    overflow: hidden;
  }
}
.M2_main {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  background: #fff;
  grid-template-rows: repeat(3, 110px);
  background: #fff;
  padding: 20px;
  .icon {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    overflow: hidden;
    .van-image {
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .no_icon {
    // background: #ccc;
  }
}
.M2_bottom {
  width: 100%;
  background: #fff;
  padding: 0 20px;
  .van-image {
    width: 100%;

    height: 125px;
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>
